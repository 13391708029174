import { IUser } from '@/modules/users/types/IUser.ts';
import { IKeysOfValue, INotKeysOfValue } from '@/types/helpers/IKeysOfValue.ts';
import { IAnalyticsEvents } from '@/types/IAnalytics.ts';
import { GOBAZAR_CONFIG } from '@/utils/configs/gobazarConfig.ts';
import { isBotVisitor } from '@/utils/isBotVisitor.ts';

const googleAnalytics = () => import('./googleAnalytics.ts');

function init() {
  setTimeout(() => {
    if (!isBotVisitor) {
      googleAnalytics().then((ga) => ga.default.init());
    }
    // @ts-ignore
    window.analyticsInit = true;
  }, 5500);
}

function identify(user: IUser | string) {
  async function identifyUser() {
    // @ts-ignore
    if (!window.analyticsInit) {
      setTimeout(identifyUser, 1500);
    } else {
      await googleAnalytics().then((ga) => ga.default.identify(user));
    }
  }
  setTimeout(identifyUser, 1000);
}

function log<K extends INotKeysOfValue<IAnalyticsEvents, undefined>>(
  name: K,
  props: IAnalyticsEvents[K],
): void;
function log<K extends IKeysOfValue<IAnalyticsEvents, undefined>>(
  name: K,
  props?: IAnalyticsEvents[K],
): void;
function log<K extends IKeysOfValue<IAnalyticsEvents, undefined>>(
  name: K,
  props?: IAnalyticsEvents[K],
) {
  async function logEvent() {
    // @ts-ignore
    if (!window.analyticsInit) {
      setTimeout(logEvent, 1500);
    } else {
      if (GOBAZAR_CONFIG.IS_DEBUGGING) {
        console.debug('------ ANALYTICS ------ ', name, props);
      }
      await googleAnalytics().then((ga) => ga.default.log(name, props));
    }
  }
  logEvent();
  return;
}

const analytics = {
  googleAnalytics,
  identify,
  init,
  log,
};

export default analytics;
